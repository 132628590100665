const MiniProfileCard = ({ width = 48, height = 48, firstName, lastName, userName, avatar, go }) => {
    return (
        <div className={"mini-card hover-pointer"} onClick={() => { go() }}>
            <img src={avatar} className={"mini-avatar"} style={{ width, height }} alt=""/>
            <div>
                <div>
                    <span className={"mini-title"}>{firstName} {lastName}</span>
                </div>
                <div className={"mini-subtitle"}>
                    <span className={"mini-name"}>{userName}</span>
                </div>
            </div>
        </div>
    )
}

export default MiniProfileCard