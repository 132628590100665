import {useEffect, useState} from 'react'
import { useNavigate } from 'react-router-dom'

import Navbar from '../components/Navbar'
import GridComponent from '../components/GridComponent'
import network from '../utils/network'
import {WebGLRenderList as data} from 'three/src/renderers/webgl/WebGLRenderLists'


const FeedScreen = () => {
    const [pagination, setPagination] = useState(-1)
    const [avatars, setAvatars] = useState([])

    const fetchData = async () => {
        const page = pagination + 1
        setPagination(page)

        await network.getData('avatars/avatars')
            .then(r => {
                setAvatars(r.allAvatars)
            })
            .catch(e => {
                console.log(e)
                alert('Sorun Var')
            })
    }

    useEffect(() => {
        fetchData()
    }, [])

    return (
        <div>
            <Navbar />
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        {
                            avatars.length > 0 &&
                            <GridComponent avatars={avatars} columnWidth={"15%"} />
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FeedScreen