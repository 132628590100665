import {useState} from 'react'
import Network from '../utils/network'

const CreateAvatar = ({ collectionId, success }) => {

    const [name, setName] = useState('')
    const [type, setType] = useState(1)
    const [content, setContent] = useState('')

    const goService = async () => {
        const formData = new FormData()
        formData.append('collectionId', collectionId)
        formData.append('name', name)
        formData.append('type', type)
        formData.append('content', content)

        const image = document.getElementById('avatar-image').files[0]
        const file = document.getElementById('avatar-file').files[0]

        formData.append('avatar', image)
        formData.append('file', file)

        await Network.postData('avatars/avatar', formData)
            .then(response => {
                success()
            })
            .catch(error => {
                console.log(error)
            })
    }

    return (
        <div className={"create-avatar card p-3"}>
            <div className="row">
                <div className="col-6">
                    <div className="row">
                        <div className="col-6">
                            <input type="text" value={name} onChange={e => { setName(e.target.value) }} placeholder={"İsim"} className={'form-control'}/>
                        </div>
                        <div className="col-6">
                            <input type="text" value={type} onChange={e => { setType(e.target.value) }} placeholder={"Tür"} className={'form-control'}/>
                        </div>
                        <div className="col-12 mt-3">
                            <textarea placeholder={"Açıklama"} value={content} onChange={e => { setContent(e.target.value) }} className={'form-control'}/>
                        </div>
                    </div>
                </div>
                <div className="col-6">
                    <div>
                        <input id={"avatar-image"} type="file" className={"form-control"}/>
                    </div>
                    <div className={"mt-3"}>
                        <input id={"avatar-file"} type="file" className={"form-control"}/>
                    </div>
                    <div className="mt-3">
                        <button onClick={() => { goService() }}>Gönder</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CreateAvatar