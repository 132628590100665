import {useState} from 'react'
import network from '../utils/network'

const CreateCollection = ({ success }) => {

    const [name, setName] = useState('')
    const [short, setShort] = useState('')
    const [long, setLong] = useState('')

    const [file, setFile] = useState({})
    const [image, setImage] = useState('')

    const importImage = event => {
        const file = event.target.files[0]
        setFile(file)

        let reader = new FileReader();
        reader.onloadend = () => {
            setImage(reader.result)
        }

        reader.readAsDataURL(file)
    }

    const createCollection = async () => {
        const formData = new FormData()
        formData.append('title', name)
        formData.append('shortDescription', short)
        formData.append('longDescription', long)
        formData.append('avatar', file)

        await network.postData('collections/collection', formData)
            .then(response => {
                success()
            })
            .catch(error => {
                console.log(error)
            })
    }

    return (
        <div className={'create-collection'}>
            <div className={'container px-0'}>
                <div className="row">
                    <div className="col-6">
                        <img className={'img-fluid'} src={image} alt={''}/>
                        <input
                            className={'d-none'}
                            type="file"
                            id={'create-collection-image'}
                            accept={'.png, .jpg, .jpeg, .PNG, .JPG, .JPEG'}
                            onChange={e => {
                                importImage(e)
                            }}
                        />
                        <button className={'file-button'} onClick={() => {
                            document.getElementById('create-collection-image').click()
                        }}>
                            Add Image
                        </button>
                    </div>
                    <div className="col-6">
                        <div>
                            <label>Collection Name</label>
                            <input
                                type="text"
                                className={'form-control mt-1'}
                                value={name}
                                onChange={e => {
                                    setName(e.target.value)
                                }}/>
                        </div>
                        <div className={'mt-3'}>
                            <label>Collection Short Description</label>
                            <textarea
                                className={'form-control mt-1'} value={short}
                                style={{height : 150}}
                                onChange={e => {
                                    setShort(e.target.value)
                                }}/>
                        </div>
                        <div className={'mt-3'}>
                            <label>Collection Long Description</label>
                            <textarea className={'form-control mt-1'} value={long}
                                      style={{height : 150}}
                                      onChange={e => {
                                          setLong(e.target.value)
                                      }}/>
                        </div>
                    </div>
                    <div className="col-12 text-center">
                        <button disabled={!name || !image} className={'file-button'} onClick={() => {
                            createCollection()
                        }}>
                            Create Collection
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CreateCollection