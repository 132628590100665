import Navbar from '../components/Navbar'
import HeroWelcome from '../components/HeroWelcome'
import HeroOrigin from '../components/HeroOrigin'
import HeroBillionMarket from '../components/HeroBillionMarket'
import HeroDarkBottom from '../components/HeroDarkBottom'

const AboutScreen = () => {
    return (
        <div>
            <Navbar />
            <HeroWelcome />
            <HeroOrigin />
            <HeroBillionMarket />
            <HeroDarkBottom />
        </div>
    )
}

export default AboutScreen