import {useState} from 'react'
import network from '../utils/network'

const SignUp = () => {
    const [step, setStep] = useState(1)

    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [birthDate, setBirthDate] = useState('')
    const [code, setCode] = useState('')

    const [token, setToken] = useState('')

    const goService = async (step) => {
        if (step === 1) {
            await network.postData('users/register', { email, password, dob: birthDate })
                .then(r => {
                    setToken(r.token)
                    setStep(2)
                })
                .catch(e => {
                    alert('Üyeliğiniz Oluşturulamadı')
                })
        }

        if (step === 2) {
            await network.postData('users/verify', { email, code })
                .then(r => {
                    localStorage.setItem('token', token)
                    localStorage.setItem('user', JSON.stringify(r))
                    window.location.href = '/'
                })
                .catch(e => {
                    alert('Üyeliğiniz Oluşturulamadı')
                })
        }
    }

    return (
        <div className={'sign-up'}>
            <div className={'sign-up-title'}>
                <div>
                    <span className={'sut-title'}>Welcome to Kraftiverse</span>
                </div>
                <div className={"mt-2"}>
                    <span className={'sut-sub-title'}>Create new ideas to try</span>
                </div>
            </div>
            {
                step === 1 ?
                    <div className={'sign-up-body'}>
                        <div>
                            <label>Email</label>
                            <input type="text" className={'form-control mt-1'} value={email} onChange={e => {
                                setEmail(e.target.value)
                            }}/>
                        </div>
                        <div className={"mt-3"}>
                            <label>Password</label>
                            <input type="text" className={'form-control mt-1'} value={password} onChange={e => {
                                setPassword(e.target.value)
                            }}/>
                        </div>
                        <div className={"mt-3"}>
                            <label>Birth Date</label>
                            <input type="text" className={'form-control mt-1'} value={birthDate} onChange={e => {
                                setBirthDate(e.target.value)
                            }}/>
                        </div>
                        <div className={"mt-3"}>
                            <button className={"sign-up-button"} disabled={!email || !password || !birthDate}
                                    onClick={() => {
                                        goService(1)
                                    }}>
                                Continue
                            </button>
                        </div>
                    </div>
                    :
                    <div className={'sign-up-body'}>
                        <div>
                            <label>E-Posta adresinize bir kod gönderdik.</label>
                        </div>
                        <div className={"mt-3"}>
                            <label>Kod</label>
                            <input type="text" className={'form-control mt-1'} value={code} onChange={e => {
                                setCode(e.target.value)
                            }}/>
                        </div>
                        <div className={"mt-3"}>
                            <button className={"sign-up-button"} disabled={code.length !== 4}
                                    onClick={() => {
                                        goService(2)
                                    }}>
                                Continue
                            </button>
                        </div>
                    </div>
            }

            <div className={'sign-up-bottom'}>
                <span>By continuing, you agree to Kraftiverse</span>
                <a> Terms of Service</a>
                <span> and acknowledge you've read our</span>
                <a> Privacy Policy</a>
                <a> Notice at collection.</a>
            </div>
        </div>
    )
}

export default SignUp