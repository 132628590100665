import {useEffect} from 'react'
import { useNavigate } from 'react-router-dom'
import Navbar from '../components/Navbar'
import HeroWelcome from '../components/HeroWelcome'
import HeroCreateIdea from '../components/HeroCreateIdea'
import HeroCreateAr from '../components/HeroCreateAr'
import HeroCards from '../components/HeroCards'
import HeroSignUp from '../components/HeroSignUp'


const LoginScreen = () => {
    useEffect(() => {
        localStorage.removeItem('token')
        localStorage.removeItem('user')
    }, [])

    return (
        <div>
            <Navbar />
            <HeroWelcome isLogin={true} isSignUp={false} />
            <HeroCreateIdea />
            <HeroCreateAr />
            <HeroCards />
            <HeroSignUp />
        </div>
    )
}

export default LoginScreen