import StackGrid, { easings } from 'react-stack-grid'
import MiniProfileCard from './MiniProfileCard'
import {useNavigate} from 'react-router-dom'
import AvatarModal3D from './AvatarModal3D'
import {useState} from 'react'
import network from '../utils/network'

const GridComponent = ({ avatars, columnWidth = 200, gutterWidth = 20, gutterHeight = 20, bottom = true }) => {
    const navigate = useNavigate()

    const [view, setView] = useState({})
    const [isView, setIsView] = useState(false)

    const getAvatar = async avatar => {
        await network.getData(`avatars/avatar/${avatar.id}`)
            .then(r => {
                setView(r)
                setIsView(true)
            }).catch(e => {
                alert(e.response.data)
            })
    }

    return (
        <>

            {
                view.product &&
                <AvatarModal3D
                    view={view}
                    open={isView}
                    close={() => { setView({}); setIsView(false) }}
                />
            }


            <StackGrid
                monitorImagesLoaded={true}
                columnWidth={columnWidth}
                gutterWidth={gutterWidth}
                gutterHeight={gutterHeight}
                appearDelay={60}
            >
                {
                    avatars.length > 0 &&
                    avatars.map((avatar, index) => (
                        <div key={index} className={"avatar-one"}>
                            <img src={avatar.avatar} alt={`Avatar ${index}`} className={"img-fluid avatar-img hover-pointer"} onClick={() => { getAvatar(avatar) }} />
                            <div className={"p-2 avatar-bottom"}>
                                <div className={bottom ? "avatar-bottom-name" : ""}>
                                    <span>{avatar.name}</span>
                                </div>
                                {
                                    bottom &&
                                    <div>
                                        <MiniProfileCard
                                            width={32}
                                            height={32}
                                            lastName={avatar.user?.lastName || 'Alyüz'}
                                            avatar={avatar.user?.avatar || 'https://cdnuploads.aa.com.tr/uploads/Contents/2017/12/07/thumbs_b_c_cae01f87087f0749b4d63a44136a0fa1.jpg'}
                                            firstName={avatar.user?.name || 'Uğurcan'}
                                            userName={avatar.user?.username}
                                            go={() => {
                                                navigate(`/profile/${avatar.user?.username}`)
                                            }}
                                        />
                                    </div>
                                }
                            </div>
                        </div>
                    ))
                }
            </StackGrid>
        </>
    )
}

export default GridComponent