const HeroCreateIdea = () => {
    return (
        <div className={"w-h-100 hero-ar"}>
            <div className="container">
                <div className="row">
                    <div className="col-4 flex-center-center">
                        <div className="kf-card">
                            <div className={"mt-2"}>
                                <span className={"kf-card-title color-green"}>Create AR Design</span>
                            </div>
                            <div className={"mt-3"}>
                                <span className={"kf-card-text color-green"}>Everyone can create amazing AR-enabled 3D objects and mint them easily as NFTs. All-in-web assets which you can see, play with, and axchange amongst each other.</span>
                            </div>
                            <div className={"mt-4 mb-2"}>
                                <button className={"kf-btn-explore-2"}>Explore</button>
                            </div>
                        </div>
                    </div>
                    <div className="col-7 offset-1">
                        <img src="/images/heroAr.png" className="img-fluid" alt=""/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HeroCreateIdea