const HeroCards = () => {
    return (
        <div className={"w-h-100 hero-origin"}>
            <div className="container">
                <div className="row">
                    <div className="col-6">
                        <img src="/images/heroCards.png" className={"img-fluid"} alt=""/>
                    </div>
                    <div className="col-5 offset-1 flex-center-center">
                        <div className="kf-card">
                            <div className={"mt-2"}>
                                <span className={"kf-card-title"}>Generative AR<br/>&<br/>Generative ART</span>
                            </div>
                            <div className={"mt-4"}>
                                <span className={"kf-card-text"}>The best part of Kraftiverse is creating new assets and converting them to AR</span>
                            </div>
                            <div className={"mt-4 mb-2"}>
                                <button className={"kf-btn-explore"}>Explore</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HeroCards