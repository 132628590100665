const HeroBillionMarket = () => {
    return (
        <div className={"w-h-100 hero-billion"}>
            <div className="container">
                <div className="row">
                    <div className="col-8 offset-2">
                        <div className="hero-container">
                            <div className="row">
                                <div className={"col-12"}>
                                    <span className={"origin-title"}>Billion Dollar Market</span>
                                </div>
                                <div className={"col-8 offset-2 mb-5 mt-5 pb-5"}>
                                    <span className={"origin-text"}>Combining 3D modeling, artificial Intelligence, programming, Augmented Reality and blockchain texhnology, the Kraftiverse platform is at the crossroads of several multibillion dollar markets.</span>
                                </div>
                                <div className="col-6">
                                    <h3>810M</h3>
                                    <span>Active Augmented</span>
                                    <br/>
                                    <span>Reality Mobile Users</span>
                                </div>
                                <div className="col-6">
                                    <h3>$10.2B</h3>
                                    <span>NFT Sales value in</span>
                                    <br/>
                                    <span>November 2021 Reality Mobile Users</span>
                                </div>
                                <div className="col-6 mt-5">
                                    <h3>20M</h3>
                                    <span>Worth of NFTs</span>
                                    <br/>
                                    <span>sold per week</span>
                                </div>
                                <div className="col-6 mt-5">
                                    <h3>3,254</h3>
                                    <span>The number of active</span>
                                    <br/>
                                    <span>NFT collections</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HeroBillionMarket