const HeroOrigin = () => {
    return (
        <div className={"w-h-100 hero-origin"}>
            <div className="container">
                <div className="row">
                    <div className="col-8 offset-2 text-center">
                        <h1 style={{ color: '#6E0F3C', fontSize: 60, fontWeight: 700 }}>Kraftiverse Origin</h1>
                        <br/>
                        <div>
                            Zwartech Technology founded in Istanbul in the Turkey in 2022, with the aim of helping
                            Gaming, Metaverse businesses create, manage and publish the 2D, 3D & AR models of their
                            products.
                        </div>
                        <div>
                            With a first product, 2D NFTs & 3D Metaverse platform called the Metasphalt, we have reached
                            a significant daily visit bt the laounch Metasphalt event.
                        </div>
                        <div>
                            During the time period of 6 months we partner with best influencers in their field and 1m+
                            people visit our contents
                        </div>
                        <div>
                            We are passionate about helping NFT creators, companies and artists generate, mint and
                            menage 2D, 3D % AR NFTs in an easy manner and want to bring their unique NFTs to life with
                            an intuitive app-less AR NFT generation tool.
                        </div>
                        <div>
                            Let`s build the WebAR-NFT Dapp based on Ethereum Developed for Blockchain
                            Commercialization.
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HeroOrigin