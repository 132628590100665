import {useEffect, useState} from 'react'
import network from '../utils/network'
import CollectionItem from './CollectionItem'
import {useNavigate} from 'react-router-dom'
import CreateCollection from './CreateCollection'

const Collections = ({ id, isEditable }) => {
    const navigate = useNavigate()

    const [create, setCreate] = useState(false)
    const [totalCount, setTotalCount] = useState(0)
    const [list, setList] = useState([])

    const mountData = async () => {
        setCreate(false)
        await network.getData(`collections/user/${id}`)
            .then(r => {
                setList(r)
            })
    }

    useEffect(() => {
        mountData()
    }, []);

    return (
        <div>
            {
                isEditable ?
                    <>
                        <div className={'flex-center-between'}>
                            <span className={'my-collections'}>My Collections</span>
                            <button className={'new-collection hover-pointer'} onClick={() => { setCreate(!create) }}>
                                {
                                    !create ? "Create New Collection" : "Cancel and Close"
                                }
                            </button>
                        </div>
                        {
                            create && <CreateCollection success={() => { mountData() }} />
                        }
                    </>
                    :
                    <div></div>
            }
            <div className="row my-5">
                {
                    list.length > 0 ?
                    list.map((item, index) => {
                        return (
                            <div className="col-6" key={Math.round(Math.random() * 999999)}>
                                <CollectionItem
                                    index={index}
                                    item={item}
                                    go={() => { navigate(`/collection/${item.id}`) }}
                                />
                            </div>
                        )
                    })
                        :
                        <div className={"my-5"}>
                            <span>Her hangi Koleksiyon bulunamadı.</span>
                        </div>
                }
            </div>
        </div>
    )
}

export default Collections