import {useEffect, useState} from 'react'
import {useNavigate} from 'react-router-dom'

const Navbar = () => {
    const navigate = useNavigate()
    const [login, setLogin] = useState('')

    const checkToken = async () => {
        const token = await localStorage.getItem('token')
        if (token) {
            setLogin(true)
        }
    }

    useEffect(() => {
        checkToken()
    }, [])

    return (
        <div className={'kf-navbar'}>
            <img className={'img-fluid'} src="../../public/images/icon.svg"/>
            <div className={'buttons'}>
                {
                    login ?
                        <button onClick={() => {
                            navigate('/feed')
                        }}>
                            Feed
                        </button>
                        :
                        <button onClick={() => {
                            navigate('/')
                        }}>
                            Home
                        </button>
                }
                <button className={'kf-navbar-business'} onClick={() => {
                    navigate('/business')
                }}>
                    Business
                </button>
                <button onClick={() => {
                    navigate('/about')
                }}>
                    About
                </button>
                <button onClick={() => {
                    window.open('https://generator.kraftiverse.com', '_blank')
                }}>
                    Generator
                </button>
                <button>
                    AR Demo
                </button>
                {
                    !login ?
                        <>
                            <button className={'kf-navbar-sign-in'} onClick={() => {
                                navigate('/login')
                            }}>
                                Login
                            </button>
                            <button className={'kf-navbar-sign-up'} onClick={() => {
                                navigate('/sign-up')
                            }}>
                                Sign Up
                            </button>
                        </>
                        :
                        <button className={'kf-navbar-sign-in'} onClick={() => {
                            navigate('/profile')
                        }}>
                            Profile
                        </button>
                }
            </div>
        </div>
    )
}

export default Navbar