import {isEditable} from '@testing-library/user-event/dist/utils'

const ProfileCard = ({ interactive, isEditable, firstName, lastName, userName, followers, following, view, isFollowing, avatar, cover }) => {
    return (
        <div className={"profile-card"}>
            <div className={"cover-image"}>
                <img src={cover} className={"img-fluid"} alt=""/>
            </div>
            <div className={"profile-image"}>
                <img className={"img-fluid"} src={avatar} alt=""/>
            </div>
            <div className="mt-4">
                <span className={"profile-name"}>{firstName && lastName ? firstName + ' ' + lastName :  '-'}</span>
            </div>
            <div className="mt-1">
                <span className={"profile-username"}>@{userName}</span>
            </div>
            <div className="mt-4">
                <span className={"profile-followers"}>{followers} Followers</span>
                <span> - </span>
                <span>{following} Following</span>
            </div>
            <div className="mt-2">
                <span>{view} Profile View</span>
            </div>
            {
                !isEditable &&
                <div className="mt-4">
                    {
                        !isFollowing ?
                            <button onClick={() => { interactive(true) }} className={"follow-button"}>Takip Et</button>
                            :
                            <button onClick={() => { interactive(false) }} className={"unfollow-button"}>Takibi Bırak</button>
                    }
                </div>
            }
        </div>
    )
}

export default ProfileCard