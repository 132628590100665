import 'react-responsive-modal/styles.css'
import {Modal} from 'react-responsive-modal'
import FBXViewer from './FBXViewer'
import {useEffect, useState} from 'react'
import MiniProfileCard from './MiniProfileCard'
import CameraFbx from './CameraFbx'

const AvatarModal3D = ({view, open, close}) => {

    const [camera, setCamera] = useState(false)
    const [load, setLoad] = useState(false)

    useEffect(() => {
        setTimeout(() => {
            setLoad(true)
        }, 1000)
    }, [])

    return (
        <Modal open={open} center onClose={() => {close()}}>
            <div className={"viewer-modal-3d mt-5"}>
                {
                    load &&
                    <>
                        <FBXViewer url={view.product} />
                        <button className={"mt-2"} onClick={() => { setCamera(true) }}>AR GÖR</button>
                        <div className={"mt-3"}>
                            <div className="row">
                                <div className="col-6">
                                    <MiniProfileCard
                                        avatar={view.user?.avatar}
                                        firstName={view.user?.name}
                                        lastName={view.user?.lastName}
                                        userName={view.user?.username}
                                    />
                                </div>
                                <div className="col-6">
                                    <div>
                                        <span>Ürün İsmi</span>
                                    </div>
                                    <div>
                                        <span>{view.name}</span>
                                    </div>
                                </div>

                                <div className="col-12 mt-4">
                                    <div>
                                        <span>Ürün Açıklaması</span>
                                    </div>
                                    <div>
                                        <span>{view.content}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                }
            </div>



            {
                camera &&
                <CameraFbx modelUrl={view.product} />
            }

        </Modal>
    )
}

export default AvatarModal3D