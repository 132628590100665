const CollectionItem = ({ item, index, go }) => {
    return (
        <div className={'collection-item hover-pointer'} onClick={() => { go() }}>
            <img src={item.avatar} alt=""/>
            <div className="collection-absolute">
                <span>{item.title}</span>
            </div>
        </div>
    )
}

export default CollectionItem