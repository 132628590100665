import {useParams} from 'react-router-dom'
import {useEffect, useState} from 'react'
import network from '../utils/network'
import Navbar from '../components/Navbar'
import MiniProfileCard from '../components/MiniProfileCard'
import GridComponent from '../components/GridComponent'
import CreateAvatar from '../components/CreateAvatar'

const CollectionScreen = () => {
    const params = useParams()
    const [data, setData] = useState({})
    const [avatars, setAvatars] = useState([])

    const [createAvatar, setCreateAvatar] = useState(false)

    const mountData = async () => {
        setCreateAvatar(false)
        await network.getData(`collections/collection/${params.id}/list`)
            .then(r => {
                setData(r)
                setAvatars(r.avatars)
                console.log(r)
            })
            .catch(e => {
                alert(e.response.data)
            })
    }

    useEffect(() => {
        if (!params.id) {
            alert('ID Bulunamadı')
        } else {
            mountData()
        }
    }, []);

    return (
        <div>
            <Navbar />
            <div className="container">
                <div className="row">
                    <div className="col-8 offset-2">
                        <div className="row">
                            <div className="col-6">
                                <img src={data.avatar} className={"img-fluid collection-cover"} alt=""/>
                            </div>
                            <div className="col-6 collection-title-right">
                                <div>
                                    <div className={'flex-center-between'}>
                                        <span className={'fw-bold'}>Koleksiyon Adı</span>
                                    </div>
                                    <div>
                                        <span>{data.title}</span>
                                    </div>
                                </div>
                                <div>
                                    <hr/>
                                </div>
                                <div>
                                    <div className={'flex-center-between'}>
                                        <span className={'fw-bold'}>Koleksiyon Özeti</span>
                                    </div>
                                    <div>
                                        <span>{data.shortDescription}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 mt-4">
                                <div className={"flex-center-between"}>
                                    <MiniProfileCard
                                        firstName={data.user?.name}
                                        lastName={data.user?.lastName}
                                        userName={data.user?.username}
                                        avatar={data.user?.avatar}
                                    />
                                    <button onClick={() => { setCreateAvatar(!createAvatar) }}>
                                        { !createAvatar ? 'Yeni Avatar Ekle' : 'Vazgeç' }
                                    </button>
                                </div>
                                {
                                    createAvatar &&
                                    <div className={"my-3"}>
                                        <CreateAvatar collectionId={params.id} success={() => { mountData() }} />
                                    </div>
                                }
                                <div className={"mt-4"}>
                                    <div className={'flex-center-between'}>
                                        <span className={'fw-bold'}>Koleksiyon Açıklaması</span>
                                    </div>
                                    <div>
                                        <span>{data.shortDescription}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 mt-4">
                                <div className={"mb-4"}>
                                    <span className={'fw-bold'}>Koleksiyona Ait Avatarlar</span>
                                </div>
                                <GridComponent
                                    avatars={avatars}
                                    columnWidth={"33%"}
                                    bottom={false}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CollectionScreen