import ProfileCard from '../components/ProfileCard'
import Navbar from '../components/Navbar'
import {useParams} from 'react-router-dom'
import {useEffect, useState} from 'react'
import network from '../utils/network'
import Collections from '../components/Collections'
import GridComponent from '../components/GridComponent'

const ProfileScreen = () => {
    const params = useParams()
    console.log(params)

    const [isEditable, setIsEditable] = useState(false)
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [username, setUsername] = useState('')
    const [followers, setFollowers] = useState(0)
    const [following, setFollowing] = useState(0)
    const [view, setView] = useState(0)
    const [isFollowing, setIsFollowing] = useState(false)
    const [avatar, setAvatar] = useState('')
    const [cover, setCover] = useState('')
    const [id, setId] = useState('')

    const [avatars, setAvatars] = useState([])

    const [menu, setMenu] = useState(0)

    const mountData = async () => {
        if (!params.username) {
            setIsEditable(true)
        }
        await network.getData(`users/user/${params.username || ''}`)
            .then(async response => {
                console.log(response)
                setFirstName(response.name)
                setLastName(response.lastName)
                setUsername(response.username)
                setFollowers(response.followers)
                setFollowing(response.followings)
                setView(response.view)
                setIsFollowing(response.isFollowing)
                setAvatar(response.avatar)
                setCover(response.cover)
                setId(response.id)

                await network.getData(`avatars/user/${response.id}`)
                    .then(response2 => {
                        setAvatars(response2.avatarInfo)
                    })
            })
            .catch(error => {
                alert(error.response.data)
            })
    }

    const interactive = async bool => {
        const obj = {userId : id}
        if (bool) {
            await network.postData('users/following', obj)
        } else {
            await network.deleteData('users/following', obj)
        }
        mountData()
    }

    useEffect(() => {
        mountData()
    }, []);

    return (
        <div>
            <Navbar/>
            <div className="container mt-5">
                <div className="row">
                    <div className="col-8 offset-2">
                        <ProfileCard
                            edit={false}
                            firstName={firstName}
                            lastName={lastName}
                            userName={username}
                            followers={followers}
                            following={following}
                            view={view}
                            isFollowing={isFollowing}
                            avatar={avatar}
                            cover={cover}
                            isEditable={isEditable}
                            interactive={bool => {
                                interactive(bool)
                            }}
                        />
                    </div>
                </div>
                <div className={'profile-menu-provider'}>
                    <div className={menu === 0 && 'profile-menu-active'}>
                        <span onClick={() => {
                            setMenu(0)
                        }} className={'profile-menu hover-pointer'}>Created</span>
                    </div>
                    <div className={menu === 1 && 'profile-menu-active'}>
                        <span onClick={() => {
                            setMenu(1)
                        }} className={'profile-menu hover-pointer'}>Collections</span>
                    </div>
                </div>
                <div className={'row'}>
                    <div className="col-8 offset-2">
                        {
                            menu === 0 ?
                                avatars.length > 0 ?
                                    <GridComponent
                                        avatars={avatars}
                                        bottom={false}
                                    />
                                    :
                                    <div>
                                        <span>Bu kullanıcı hiç bir şey yüklememiş.</span>
                                    </div>
                                :
                                <Collections
                                    id={id}
                                    isEditable={isEditable}
                                />
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ProfileScreen