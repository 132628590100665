const HeroCreateIdea = () => {
    return (
        <div className={"w-h-100 hero-origin"}>
            <div className="container">
                <div className="row">
                    <div className="col-7">
                        <img src="/images/heroCreateIdea.png" className="img-fluid" alt=""/>
                    </div>
                    <div className="col-5 flex-center-center">
                        <div className="kf-card">
                            <div className={"mt-2"}>
                                <span className={"kf-card-title"}>Create an idea</span>
                            </div>
                            <div className={"mt-3"}>
                                <span className={"kf-card-text"}>We create multiple variations of the 2D asset and generate collection which are minted into the wallet of the user. In only a few clicks, users can create amazing 2D generated NTFs and mint them</span>
                            </div>
                            <div className={"mt-4 mb-2"}>
                                <button className={"kf-btn-explore"}>Explore</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HeroCreateIdea