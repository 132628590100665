import axios from "axios"

const Network = class NETWORK {
    constructor(axios) {
        this.network = axios.create({
            // baseURL: 'http://127.0.0.1:7070/kf'
            baseURL: "https://development.codelisa.com.tr/kf"
        })

        this.network.interceptors.request.use(async (config) => {
            const token = localStorage.getItem("token")
            if (token) {
                config.headers.authorization = `Bearer ${token}`
            }
            return config
        })

        this.network.interceptors.response.use(null, (error) => {
            console.log(error)
            /*
            if (error.response.status === 401) {
                localStorage.removeItem("token")
                window.location.href = "/login"
            }

             */
            return Promise.reject(error)
        })
    }

    getData = async (path) => {
        return await this.network
            .get(path)
            .then((r) => (r.data.data ? r.data.data : r.data))
    }

    postData = async (path, body) => {
        return await this.network.post(path, body).then((r) => r.data)
    }

    putData = async (path, body) => {
        return await this.network.put(path, body).then((r) => r.data)
    }

    patchData = async (path, body) => {
        return await this.network.patch(path, body).then((r) => r.data)
    }

    deleteData = async (path, body) => {
        return await this.network({
            method: "DELETE",
            data: body,
            url: path
        }).then((r) => r.data)
    }
}
export default new Network(axios)
