import React, {useEffect, useRef} from 'react';
import * as THREE from 'three';
import {FBXLoader} from 'three/examples/jsm/loaders/FBXLoader';
import {OrbitControls} from 'three/examples/jsm/controls/OrbitControls';

function FBXViewer({url}) {
    const ref = useRef();

    useEffect(() => {
        const scene = new THREE.Scene();
        const camera = new THREE.PerspectiveCamera(45, 760 / 400, 1, 2000);
        camera.position.set(100, 200, 300);

        const renderer = new THREE.WebGLRenderer({antialias : true});
        renderer.setPixelRatio(window.devicePixelRatio);
        renderer.setSize(760, 400);
        ref.current.appendChild(renderer.domElement);

        const controls = new OrbitControls(camera, renderer.domElement);
        controls.minDistance = 100;
        controls.maxDistance = 800;
        controls.enablePan = false;
        controls.enableDamping = true;
        controls.dampingFactor = 0.05;
        controls.autoRotate = false; // Set this to true to make the camera rotate around the model

        const loader = new FBXLoader();
        loader.load(url, (fbx) => {
                const box = new THREE.Box3().setFromObject(fbx);

                const size = box.getSize(new THREE.Vector3());
                console.log(size)
                const maxDim = Math.max(size.x, size.y, size.z);
                console.log(maxDim)

                const total = maxDim / 250
                let scale = 1.0
                const round = Math.round(total)
                for (let i = 0; i < round; i = i + 1) {
                    scale = scale - 0.1
                }
                fbx.scale.set(scale, scale, scale);
                scene.add(fbx);
            },
            undefined,
            function (error) {
                console.error('An error happened', error);
            });

        const light = new THREE.HemisphereLight(0xffffff, 0x444444);
        light.position.set(0, 400, 0); // Adjust the light position
        scene.add(light);

        const animate = function () {
            requestAnimationFrame(animate);
            // update the controls
            controls.update();
            renderer.render(scene, camera);
        };

        animate();

    }, [url]);

    return <div className={'hover-pointer'} ref={ref}/>;
}

export default FBXViewer;