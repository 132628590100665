import Navbar from '../components/Navbar'
import HeroWelcome from '../components/HeroWelcome'
import HeroCreateIdea from '../components/HeroCreateIdea'
import HeroCreateAr from '../components/HeroCreateAr'
import HeroCards from '../components/HeroCards'
import HeroSignUp from '../components/HeroSignUp'

const HomeScreen = () => {


    return (
        <div>
            <Navbar />
            <HeroWelcome isLogin={false} isSignUp={false} />
            <HeroCreateIdea />
            <HeroCreateAr />
            <HeroCards />
            <HeroSignUp />
        </div>
    )
}

export default HomeScreen