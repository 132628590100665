import {useState} from 'react'
import network from '../utils/network'
import {useNavigate} from 'react-router-dom'

const Login = () => {
    const navigate = useNavigate()
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')

    const goService = async () => {
        await network.postData('users/login', {email, password})
            .then(r => {
                localStorage.setItem('token', r.token)
                localStorage.setItem('user', JSON.stringify(r))
                navigate('/feed')
            })
            .catch(e => {
                alert('Giriş Hatalıdır')
            })
    }

    return (
        <div className={'sign-up'} style={{height : 525}}>
            <div className={'sign-up-title'}>
                <div>
                    <span className={'sut-title'}>Login to Kraftiverse</span>
                </div>
                <div className={'mt-2'}>
                    <span className={'sut-sub-title'}>Create new ideas to try</span>
                </div>
            </div>

            <div className={'sign-up-body'} style={{height : 275}}>
                <div>
                    <label>Email</label>
                    <input type="text" className={'form-control mt-1'} value={email} onChange={e => {
                        setEmail(e.target.value)
                    }}/>
                </div>
                <div className={'mt-3'}>
                    <label>Password</label>
                    <input type="text" className={'form-control mt-1'} value={password} onChange={e => {
                        setPassword(e.target.value)
                    }}/>
                </div>
                <div className={'mt-3'}>
                    <button className={'sign-up-button'} disabled={!email || !password}
                            onClick={() => {
                                goService(1)
                            }}>
                        Continue
                    </button>
                </div>
            </div>
            <div className={'sign-up-bottom'}>
                <span>By continuing, you agree to Kraftiverse</span>
                <a> Terms of Service</a>
                <span> and acknowledge you've read our</span>
                <a> Privacy Policy</a>
                <a> Notice at collection.</a>
            </div>
        </div>
    )
}

export default Login