import React from 'react'
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom'

import HomeScreen from './screens/HomeScreen'
import LoginScreen from './screens/LoginScreen'
import FeedScreen from './screens/FeedScreen'
import AboutScreen from './screens/AboutScreen'
import BusinessScreen from './screens/BusinessScreen'
import SignUpScreen from './screens/SignUpScreen'
import ProfileScreen from './screens/ProfileScreen'
import CollectionScreen from './screens/CollectionScreen'

const App = () => {
    return (
        <Router>
            <Routes>
                <Route path="/" exact element={<HomeScreen/>}/>
                <Route path="/about" element={<AboutScreen/>}/>
                <Route path="/login" element={<LoginScreen/>}/>
                <Route path="/sign-up" element={<SignUpScreen/>}/>
                <Route path="/feed" element={<FeedScreen/>}/>
                <Route path="/profile" element={<ProfileScreen/>}/>
                <Route path="/profile/:username" element={<ProfileScreen/>}/>
                <Route path="/business" element={<BusinessScreen />}/>
                <Route path="/collection/:id" element={<CollectionScreen/>}/>
            </Routes>
        </Router>
    )
}

export default App