import Login from './Login'
import SignUp from './SignUp'

const HeroWelcome = ({ isLogin = false, isSignUp = false }) => {
    return (
        <div className={"w-h-100"}>
            {
                isLogin || isSignUp ?
                    <div className={"login-provider"}>
                        <img className={'img-fluid bottom-spicy'} src="/images/heroWelcome.png" alt=""/>
                        <div className={"login-container"}>
                            <div className="container">
                                <div className="row login-center">
                                    <div className="col-5">
                                        {
                                            (isLogin && !isSignUp) ?
                                                <Login />
                                                : (!isLogin && isSignUp) ?
                                                <SignUp />
                                                : <></>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    <img className={'img-fluid bottom-spicy'} src="/images/heroWelcome.png" alt=""/>
            }
        </div>
    )
}

export default HeroWelcome