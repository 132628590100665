import SignUp from './SignUp'

const HeroSignUp = () => {
    return (
        <div className={'w-h-100 flex-center-center'} style={{ position: 'relative' }}>
            <img src="/images/heroDarkBottom.png" className="img-fluid" alt=""/>
            <div className="hero-absolute">
                <div className="container">
                    <div className="row">
                        <div className="col-6 flex-center-center">
                            <h3 className={"bottom-title"}>Sign up to
                                create your
                                ideas</h3>
                        </div>
                        <div className="col-5 offset-1">
                            <SignUp />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HeroSignUp