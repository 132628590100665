import React, { useEffect, useRef } from 'react';
import { Canvas, useLoader } from '@react-three/fiber';
import { FBXLoader } from 'three/examples/jsm/loaders/FBXLoader';
import { ARButton, VRButton, XR, Controllers, Hands } from '@react-three/xr';

function Model({ url }) {
    const fbx = useLoader(FBXLoader, url);
    const group = useRef();

    useEffect(() => {
        if (fbx) {
            group.current.add(fbx);
        }
    }, [fbx]);

    return <group ref={group} />;
}

function CameraFbx({ modelUrl }) {
    return (
        <>
            <ARButton />
            <Canvas>
                <ambientLight intensity={1.25} />
                <ambientLight intensity={0.1} />
                <directionalLight intensity={0.4} />
                <XR>
                    <Model url={modelUrl} />
                </XR>
            </Canvas>
        </>
    );
}

export default CameraFbx;
